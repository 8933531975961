<template>
    <Setup-Step
        @next="f => $emit('next', f)"
        fluid
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-package-consign"
        title="Package Consignment">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightPackageConsign title="Order Pipeline: Package Consignment" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="picking-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Package Consignment</v-subheader>

                        <p>
                            Package consignments are the next step in the pipeline.  They can have packages assigned to them and you can request a courier to pickup and/or deliver.  
                            Stock consignments can proceed to package consignments automatically after a supply location is identified or manually by the user.
                            You can also decide to set it here globally for every customer or individually for each customer.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.dispatchRequestGuide == 'Settings' ? 'Apply To Every Customer' : 'Different For Each Customer'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.dispatchRequestGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.dispatchRequestGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.dispatchRequestTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.dispatchRequestTrigger" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Calculating Consignment Weight / Packages</v-subheader>

                        <p>
                            When progressing to a package consignment, BlitzIt Web can automatically calculate the weight of the consignment and how many packages there will be.  
                        </p>

                        <p>
                            (To calculate weight products need to be have their weight defined)
                        </p>

                        <v-row>
                            <v-col cols="6">
                                <BT-Square-Check
                                    @change="save"
                                    falseIcon="mdi-boom-gate"
                                    falseLabel="Calculate Weight: OFF"
                                    isEditing
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueLabel="Calculate Weight: ON"
                                    v-model="item.calculateWeight" />
                            </v-col>
                            <v-col cols="6">
                                <BT-Square-Check
                                    @change="save"
                                    falseIcon="mdi-boom-gate"
                                    falseLabel="Calculate Packages: OFF"
                                    isEditing
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueLabel="Calculate Packages: ON"
                                    v-model="item.calculateConsignmentPackages" />
                            </v-col>
                        </v-row>
<!-- 
                        <v-slide-x-transition>
                            <BT-Select-List
                                v-if="item.calculateConsignmentPackages"
                                @change="save"
                                v-model="item.consignmentPackageIDs"
                                chips
                                itemText="measurementName"
                                itemValue="id"
                                multiple
                                returnCSV
                                outlined
                                navigation="measurements" />
                        </v-slide-x-transition> -->

                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Stock</v-subheader>

                        <p>
                            You might only want the order pipeline to proceed if all the stock for the order is assigned to it - especially if you use a make-to-stock (MTS) approach.  
                            Alternatively, if you make-to-order (MTO) you might want BlitzIt Web to proceed to a package consignment regardless of whether there is enough stock for the order.  
                        </p>

                        <v-row>
                            <v-col cols="6">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate-arrow-up"
                                    falseLabel="Ignore Stock Provisioning"
                                    :isEditing="item.dispatchRequestGuide == 'Settings'"
                                    outlined
                                    trueIcon="mdi-boom-gate-alert"
                                    trueLabel="Must Be Fully Provisioned"
                                    v-model="item.mustFullyProvision" />
                            </v-col>
                            <!-- <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.provisionGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.provisionTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.provisionTrigger" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col> -->
                        </v-row>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Package-Consign-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>